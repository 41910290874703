<template>
  <VuePdfEmbed
    :source="$translate(document.urls)"
    data-qa="pdf-viewer"
    :scale="2"
    @rendered="onRendered"
  />
  <div
      v-if="loading"
      class="loading-wrapper"
    >
    <v-progress-circular
      class="my-6"
      size="64"
      indeterminate
    />
  </div>
  <component
    :is="confirmationRenderer"
    :key="document.id"
    :document="document"
    @confirm="onConfirm"
    @back="$emit('back')"
  />
</template>
<script setup lang="ts">
import { type PropType, computed, ref, defineAsyncComponent } from 'vue';
import type { IVisitDocument, VisitDocumentType, ScannerDocument } from '@einfachgast/shared';
import SignatureConfirmation from '@/components/dynamic-form/renderers/document/SignatureConfirmation.vue';
import NoConfirmation from '@/components/dynamic-form/renderers/document/NoConfirmation.vue';
import CheckboxConfirmation from '@/components/dynamic-form/renderers/document/CheckboxConfirmation.vue';
import { translate } from '@/plugins/translator';

const emit = defineEmits<{
  (e: 'confirm', value: IVisitDocument): void,
  (e: 'back'): void}
>();
const VuePdfEmbed = defineAsyncComponent(() => import('vue-pdf-embed'));
const props = defineProps({
  document: {
    type: Object as PropType<ScannerDocument>,
    required: true,
    default: () => ({} as ScannerDocument),
  },
});
const loading = ref(true);

const onConfirm = (value: string | boolean) => {
  let visitDocumentType = 'none';
  switch (props.document.confirmationType) {
    case 'checkbox':
    case 'optionalCheckbox':
      visitDocumentType = 'checkbox';
      break;
    case 'signature':
      visitDocumentType = 'signature';
      break;
    default:
      break;
  }
  const visitDoc: IVisitDocument = {
    name: translate(props.document.name),
    id: props.document.id,
    path: translate(props.document.content),
    checkboxLabel: translate(props.document.confirmationCaption),
    type: visitDocumentType as VisitDocumentType,
    documentType: props.document.type,
    value,
  };

  emit('confirm', visitDoc);
};

const confirmationRenderer = computed(() => {
  if (props.document.confirmationType === 'signature') {
    return SignatureConfirmation;
  }
  if (['optionalCheckbox', 'checkbox'].includes(props.document.confirmationType)) {
    return CheckboxConfirmation;
  }
  return NoConfirmation;
});

const onRendered = () => {
  loading.value = false;
};
</script>
<style lang="scss" scoped>
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  position: fixed;
  top: 0;
  background: #ffffffb5;
  z-index: 9999;
  width: 100%;
  left: 0;
}

.download-btn-wrapper {
  /*position: fixed;
  right: 20px;
  bottom: 20px;*/
  .v-btn {
    color: #374151;
    font-size: 16px;
    font-weight: normal !important;
    background: #E5E7EB;
    border: none;
  }
}
</style>